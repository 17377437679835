import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../state/Auth/Action'
import AuthModal from '../auth/AuthModal';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const AQuang = [
    { name: 'Báo cáo ', href: '/BaoCaoBanHangTonKhoHeThong', current: true },
    { name: 'Báo cáo tồn kho', href: '/BaoCaoTonKhoHeThong', current: true },
  ]
  const Kho = [
    { name: 'Báo cáo tồn kho', href: '/BaoCaoTonKhoHeThong', current: true },
  ]
  const _localKey = localStorage.getItem('ekey') !== null ? (localStorage.getItem('ekey')) : '';
  const _localUser = localStorage.getItem('name') !== null ? (localStorage.getItem('name')) : '';
  const [navigation,setNavigation] = useState([]);


  const [open, setOpen] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState(true);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const jwt = localStorage.getItem("jwt");
  const { auth } = useSelector(store => store)
  const handleLogin = () => {
navigate("/login")
    //setOpenAuthModal(true);

  }
  const handleClose = () => {
    setOpenAuthModal(false);

  }
  
  useEffect(()=>{
    setNavigation([]);



   
    if (_localKey == '6cfe2b93-0d55-4c7a-8f87-b8e930e3c886')
      {
        const updatedArray = AQuang;
        updatedArray.forEach((element,index) => {
          
          updatedArray[index].current = false;
          if (element.href === location.pathname) {
            updatedArray[index].current = true;
          }
      
        });
        setNavigation(updatedArray)
      }
      else
      {
        
        if(_localKey === '837139ef-d926-467d-8e30-3b4d41cf8af0')
        {
          const updatedArray = AQuang;
          updatedArray.forEach((element,index) => {
            
            updatedArray[index].current = false;
            if (element.href === location.pathname) {
              updatedArray[index].current = true;
            }
        
          });
          setNavigation(updatedArray)
        }
        else
        if(_localKey === '46d6df42-a086-4c19-b0b4-57323a963b18')
        {
          const updatedArray = Kho;
          updatedArray.forEach((element,index) => {
            
            updatedArray[index].current = false;
            if (element.href === location.pathname) {
              updatedArray[index].current = true;
            }
        
          });
          setNavigation(updatedArray)
        }
        else
          setNavigation([])
          
      }
    
  },[])
  useEffect(() => {
    if (_localUser) {
      handleClose();
    }
    if (location.pathname === "/login" || location.pathname === "/register") {
      navigate(-1)
    }
    


  }, [])
  const handleLogout = () => {
    dispatch(logout());
    setNavigation([])
  }
  return (
    <Disclosure as="nav" className="bg-gray-800 w-full/2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href='/home'>
                  <img 
                    className="h-8 w-auto"
                    src="/ptficon.ico"
                    alt="Your Company"
                  />
                  </a>
                  
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}

                {_localUser ? (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className=' text-white' >{_localUser}</span>
                        {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://scontent.fsgn8-3.fna.fbcdn.net/v/t39.30808-6/241051232_10227457570364585_4485862198020304896_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=S6BxBEYnfS4AX9KROyy&_nc_ht=scontent.fsgn8-3.fna&oh=00_AfACYCDXC2UOf5a9sOKKaLyhafHyM_nil6IFJ-PWTulAPw&oe=6551B46B"
                        alt=""
                      /> */}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={handleLogout}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div className='flex items-center gap-[15px] relative'>

                    <button className='text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-gray-600 rounded-md' onClick={handleLogin}>Login</button>
                  </div>


                )}





              </div>
            </div>
            <AuthModal handleClose={handleClose} open={openAuthModal} />
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
