import { Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from '../state/store';
import { getUser, register } from '../state/Auth/Action';

export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt=localStorage.getItem("jwt");
  const {auth} = useSelector(store=>store)
  useEffect(()=>{
    if(jwt){
      
      dispatch( getUser(jwt))
    }
    
  },[jwt,auth.jwt])

  const handleSubmit=(event)=>{
    event.preventDefault()
    const data= new FormData(event.currentTarget);
    const userData={
      tenDangNhap:data.get("tenDangNhap"),
      matKhau:data.get("matKhau"),
      hoTen:data.get("hoTen"),
    }
    dispatch(register(userData))
    navigate("/login");
  }
  return (
    <div>

    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField required id='tenDangNhap' name='tenDangNhap' label='Tên đăng nhập' fullWidth autoComplete='given-name' />
        </Grid>
        <Grid item xs={12}>
          <TextField required id='hoTen' name='hoTen' label='Tên hiển thị' fullWidth autoComplete='given-name' />
        </Grid>
        <Grid item xs={12} >
          <TextField required type='password' id='matKhau' name='matKhau' label='Mật khẩu' fullWidth  /> 
        </Grid>
        <Grid item xs={12} >
          <Button className=' bg-[#9155FD] w-full' type='submit' variant='contained' size='large' sx={{padding:".8rem 0", bgcolor:"#9155FD"}}>Register</Button>
        </Grid>
      </Grid>
    </form>
    <div className='flex justify-center flex-col items-center'>
      <div className='py-3 flex items-center'> 
        <p>if you have already account ?</p>
        <Button onClick={()=>navigate("/login")} className=' ml-5 ' size='small'>Login</Button>
      </div>
    </div>

    </div>
  )
}
