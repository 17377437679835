import React from 'react'
import {  Navigate, Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Home'
import LoginForm from '../auth/LoginForm'
import BaoCaoTonKhoHeThong from '../components/BaoCaoTonKho/BaoCaoTonKhoHeThong'
import BaoCaoBanHangTonKhoHeThong from '../components/BaoCaoTonKho/BaoCaoBanHangTonKhoHeThong'

export default function () {
  return (
    <Routes>
        <Route path='/' element={<Navigate to='/home'/>} />
        {/* <Route path='/home' element={<Home/>} /> */}
        <Route path='/login' element={<LoginForm/>} />
        <Route path='/register' element={<Home/>} />
        <Route path='/BaoCaoBanHangTonKhoHeThong' element={<BaoCaoBanHangTonKhoHeThong />} />
        <Route path='/BaoCaoTonKhoHeThong' element={<BaoCaoTonKhoHeThong/>} />
        
       
    </Routes>
  )
}
