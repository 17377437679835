import React from 'react'
import { Box, Modal } from '@mui/material';
export default function MsBoxModal({ handleClose, open,Message,width }) {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: {width},
      bgcolor: 'background.paper',
      outline: 'none',
      boxShadow: 24,
      p: 1,
    };
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
  
            {Message}
          </Box>
        </Modal>
      </div>
    )
}
